<template>
  <div class="col-6 col-md-6">
    <b-card>
      <h4>สมัครสมาชิก</h4>
      <div class="col-12 col-md-12">
        <div class="row">
          <label>เอเจนต์ {{ oldSelect }}</label>
          <b-form-select
            v-model="selectedAgents"
            :options="optionsAgents"
            class="form-control"
          />
        </div>
        <div class="col-12 col-md-12 d-flex justify-content-end">
          <b-button
            :variant="changeButton.color"
            :disabled="changeButton.disabled"
            class="mt-1"
            @click="onSubmit"
          >
            ยืนยัน
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormSelect, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      selectedAgents: '',
      oldSelect: '',
      optionsAgents: [
        {
          value: 'สุ่มเลือกเอเจนต์', text: 'สุ่มเลือกเอเจนต์',
        },
      ],
      changeButton: {
        color: 'secondary',
        disabled: true,
      },
      id: '',
    }
  },
  watch: {
    selectedAgents() {
      if (this.oldSelect !== this.selectedAgents) {
        this.changeButton.color = 'success'
        this.changeButton.disabled = false
      } else {
        this.changeButton.color = 'secondary'
        this.changeButton.disabled = true
      }
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      await this.loadAgent()
      await this.loadConfig()
    },
    async loadAgent() {
      this.$store.dispatch('agent/getAllAgent').then(result => {
        result.data.items.forEach(element => {
          const newData = {
            value: element.agent,
            text: element.agent,
          }
          this.optionsAgents.push(newData)
        })
      }).catch(error => {
        console.log(error)
      })
    },
    async loadConfig() {
      this.$store.dispatch('config/getConfig').then(result => {
        this.selectedAgents = result.data.items[0].value
        this.oldSelect = this.selectedAgents
        // eslint-disable-next-line no-underscore-dangle
        this.id = result.data.items[0]._id
      }).catch(error => {
        console.log(error)
      })
    },
    onSubmit() {
      const payload = {
        id: this.id,
        value: this.selectedAgents,
        detail: this.selectedAgents,
      }
      this.$store.dispatch('config/editRegisterAgent', payload).then(result => {
        if (result.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ตั้งค่า',
              icon: 'SettingsIcon',
              variant: 'success',
              text: result.data.message,
            },
          })
          this.oldSelect = this.selectedAgents
          this.changeButton.color = 'secondary'
          this.changeButton.disabled = true
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ตั้งค่า',
              icon: 'SettingsIcon',
              variant: 'danger',
              text: result.data.message,
            },
          })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ตั้งค่า',
            icon: 'SettingsIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
