<template>
  <div class="container">
    <div class="row">
      <setting-agent />
      <setting-game />
    </div>
  </div>
</template>

<script>
import settingAgent from './settingAgent.vue'
import settingGame from './settingGame.vue'

export default {
  components: {
    settingAgent,
    settingGame,
  },
  data() {
    return {
    }
  },
}
</script>

<style>

</style>
