<template>
  <div class="col-6 col-md-6">
    <b-card>
      <h4>เกมส์</h4>
      <div class="col-12 col-md-12">
        <div class="row">
          <b-button
            size="md"
            variant="warning"
            :disabled="loading"
            @click="onResetButton()"
          >
            <b-spinner
              v-if="loading"
              small
            /> อัพเดตเกมส์
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    onResetButton() {
      this.loading = true
      this.$store.dispatch('game/updateStatus').then(result => {
        if (result.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ตั้งค่า',
              icon: 'SettingsIcon',
              variant: 'success',
              text: result.data.message,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ตั้งค่า',
              icon: 'SettingsIcon',
              variant: 'danger',
              text: result.data.message,
            },
          })
        }
        this.loading = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ตั้งค่า',
            icon: 'SettingsIcon',
            variant: 'danger',
            text: error.message,
          },
        })
        this.loading = false
      })
    },
  },
}
</script>

<style>

</style>
